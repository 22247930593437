import {Component, EventEmitter, Output, output} from '@angular/core';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [],
  template: `
    <div
      (click)="onBackdropClick($event)"
      class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
    >
      <div
        class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full relative"
        (click)="onModalContentClick($event)"
      >
        <button
          (click)="this.close.emit()"
          class="absolute top-3 right-5 text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrl: './modal.component.css',
})
export class ModalComponent {
  public close = output<void>();

  onBackdropClick(event: MouseEvent): void {
    this.close.emit();
  }

  onModalContentClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
